import React, { useEffect, useState, useRef, useContext } from "react";
import { resizeNavBar } from '../Components/NavBarJs'
import MiContexto from '../MiContexto'
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import { use } from "i18next";
import './DemoDevice.css'
import { getRandomNumber, playAudio } from '../Components/Features/functions';
import Moment from "react-moment";
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Messages from './Features/Messages/Messages';
import Whatsapp from './Features/Messages/Whatsapp';
import Instagram from './Features/Messages/Instagram';
import Facebook from './Features/Messages/Facebook';
import DevicePictures from './Features/DevicePictures';
import Location from './Features/Location';
import NetworkData from './Features/NetworkData';






function DemoDevice(props) {

    const { t } = useTranslation();
    const { mobile } = props
    const { number: currentNumber, location, network, hardware, wppPicture, wppAbout } = mobile
    const { demoMode } = useContext(MiContexto)

    const [isLoading, setIsLoading] = useState(true);
    const [aHardware, setHardware] = useState({ imei: "", serial: "", model: "", os: "" })
    const [aNetwork, setNetwork] = useState({ domain: "", organization: "", ip: "", areaCode: "" })
    const [aLocation, setLocation] = useState({ country: "", flag: "", city: "", code: "", cp: "", lat: "", lng: "" })

    const [aBatteryLevel, setBatteryLevel] = useState(0)
    const [aSignalLevel, setSignalLevel] = useState(0)
    const [aConnectionType, setConnectionType] = useState(0)
    const [aGpsStatus, setGpsStatus] = useState(0)
    const [aBlueToothStatus, setBlueToothStatus] = useState(0)
    const [aSoundMode, setSoundMode] = useState(0)

    const [unreadWpp, setUnreadWpp] = useState(0)
    const [unreadInsta, setUnreadInsta] = useState(0)
    const [unreadFb, setUnreadFb] = useState(0)
    const [unreadImages, setUnreadImages] = useState(0)


    const [currentChannel, setCurrentChannel] = useState("")



    useEffect(() => {

        setBatteryLevel(getRandomNumber(5, 100))
        setSignalLevel(getRandomNumber(5, 100))
        setConnectionType(getRandomNumber(1, 2))
        setGpsStatus(getRandomNumber(1, 2))
        setBlueToothStatus(getRandomNumber(1, 2))
        setSoundMode(getRandomNumber(1, 3))

        setUnreadWpp(getRandomNumber(1, 13))
        setUnreadInsta(getRandomNumber(0, 2))
        setUnreadFb(getRandomNumber(0, 1))
        setUnreadImages(getRandomNumber(0, 10))


        const init = async () => {
            await new Promise(r => setTimeout(r, getRandomNumber(3000, 6000)));
            setIsLoading(false)
            playAudio('noty')
        }
        init()


        let imei = hardware.split("|")[0]
        let serial = hardware.split("|")[1]
        let model = hardware.split("|")[2]
        let os = hardware.split("|")[3]
        setHardware({ imei: imei, serial: serial, model: model, os: os })


        let domain = network.split("|")[0]
        let organization = network.split("|")[1]
        let ip = network.split("|")[2]
        setNetwork({ domain: domain, organization: organization, ip: ip, areaCode: mobile.code })


        let country = location.split("|")[0]
        let flag = location.split("|")[1]
        let city = location.split("|")[2]
        let code = location.split("|")[3]
        let cp = location.split("|")[4]
        let lat = location.split("|")[5]
        let lng = location.split("|")[6]
        setLocation({ country: country, flag: flag, city: city, code: code, cp: cp, lat: lat, lng: lng })



    }, [])



    const getBatteryLevel = () => {
        let iconPading = { paddingTop: "0.2em" }

        if (aBatteryLevel >= 0 && aBatteryLevel <= 10)
            return <>{aBatteryLevel}%<i className="fa fa-battery-empty mx-1" style={iconPading} /></>
        if (aBatteryLevel > 10 && aBatteryLevel <= 30)
            return <>{aBatteryLevel}%<i className="fa fa-battery-quarter mx-1 " style={iconPading} /></>
        if (aBatteryLevel > 30 && aBatteryLevel <= 60)
            return <>{aBatteryLevel}%<i className="fa fa-battery-half mx-1" style={iconPading} /></>
        if (aBatteryLevel > 60 && aBatteryLevel <= 90)
            return <>{aBatteryLevel}%<i className="fa fa-battery-three-quarters mx-1" style={iconPading} /></>
        if (aBatteryLevel > 90 && aBatteryLevel <= 100)
            return <>{aBatteryLevel}%<i className="fa fa-battery-full mx-1" style={iconPading} /></>
    }
    const getSignalLevel = () => {
        // let aSignalLevel = getRandomNumber(5, 100)
        // return <i className="fa fa-signal mr-1" />
        return (
            <div className="" style={{ padding: '0px', marginTop: "-8px" }}>
                <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src="/images/lottie-signal.json"
                    style={{ height: '30px', width: '30px', padding: '0px' }}
                ></Player>
            </div>
        )
    }

    const getConnectionType = () => {
        if (aConnectionType == 1) {
            return <i className="fa fa-wifi mr-1 " style={{ paddingTop: "0.2em" }} />
        }
        if (aConnectionType == 2) {
            return <i className="fa fa-exchange mr-1   " style={{ paddingTop: "0.2em" }} />
        }
    }
    const getGpsStatus = () => {
        if (aGpsStatus == 1) {
            return <i className="sharp fa-solid fa-location-dot mx-1" style={{ paddingTop: "0.2em" }} />
        }
        // if (gpsEnabled == 2) {
        //     return <i className="fa fa-map-marker text-danger mr-1" />
        // }
        return null
    }
    const getBlueToothStatus = () => {
        let iconPading = { paddingTop: "0.2em" }

        if (aBlueToothStatus == 1) {
            return <i className="fa-brands fa-bluetooth mr-1" style={iconPading} />
        }
        // if (blueToothEnabled == 2) {
        //     return <i className="fa fa-bluetooth text-danger mr-1" />
        // 
        return null
    }

    const getSoundMode = () => {
        let iconPading = { paddingTop: "0.2em" }
        if (aSoundMode == 1) {
            return <i className="fa fa-volume-off mr-2 " style={iconPading} />
        }
        if (aSoundMode == 2) {
            return <i className="fa fa-volume-down mr-2 " style={iconPading} />
        }
        if (aSoundMode == 3) {
            return <i className="fa fa-volume-up mr-2 " style={iconPading} />
        }
    }

    const getNotificationBadge = (number) => {
        if (number == 0) return null
        return <span className="badge badge-danger" style={{ position: 'absolute', left: "35px", borderRadius: '10px', top: '-6px' }}>{number}</span>
    }


    const handleChannelChange = (channel) => {
        playAudio('clic')
        setCurrentChannel(channel)
    }

    const handleHomeClick = () => {
        playAudio('clic')
        setCurrentChannel("")
    }


    return (
        <div className={"container"}>

            <div className="my-2 d-flex justify-content-center">
                {aLocation && <img src={aLocation.flag} alt="flag" className='img-fluid' style={{ height: "30px" }} />}
                <div className="ml-1 text-danger" style={{ fontSize: "1.6em", letterSpacing: "0.03em", fontWeight: "bold" }}>{currentNumber}</div>
            </div>

            <div className="mobileContainer " style={{ width: "300px" }}>

                <div className="foreground-content">

                    <div style={{ position: "absolute", paddingInline: "10px", marginTop: "27px", zIndex: "9999" }}>
                        <Whatsapp chatsNumbers={20} currentNumber={currentNumber} currentChannel={currentChannel} unread={unreadWpp} profilePic={wppPicture} wppAbout={wppAbout} demoMode={demoMode} />
                        <Instagram chatsNumbers={20} currentNumber={currentNumber} currentChannel={currentChannel} unread={unreadInsta} profilePic={wppPicture} demoMode={demoMode} />
                        <Facebook chatsNumbers={20} currentNumber={currentNumber} currentChannel={currentChannel} unread={unreadFb} profilePic={wppPicture} demoMode={demoMode} />
                        <DevicePictures profilePic={wppPicture} currentNumber={currentNumber} currentChannel={currentChannel} handleBuy={() => props.handleBuy(currentNumber)} demoMode={demoMode} />
                        <Location aLocation={aLocation} currentNumber={currentNumber} currentChannel={currentChannel} handleBuy={() => props.handleBuy(currentNumber)} demoMode={demoMode} />
                        <NetworkData currentNumber={currentNumber} currentChannel={currentChannel} aLocation={aLocation} aHardware={aHardware} aNetwork={aNetwork} demoMode={demoMode} />
                    </div>


                    {!isLoading ?
                        <React.Fragment>

                            <div className="top-content">
                                <div className="d-flex justify-content-between pt-1">

                                    <div className="d-flex ">
                                        <span className="mr-1">  <Moment format="HH:mm" /></span>
                                        {getBlueToothStatus()}
                                        {getGpsStatus()}
                                    </div>

                                    <div className="d-flex ">
                                        {getSoundMode()}
                                        {getConnectionType()}
                                        {getSignalLevel()}
                                        {getBatteryLevel()}

                                    </div>

                                </div>
                            </div>

                            <div style={{ paddingTop: "25px", fontSize: "1.4em", fontWeight: "300", textTransform: "uppercase" }}>
                                {aNetwork.domain}
                            </div>

                            <div className="" style={{ padding: '0px', marginTop: "-8px" }}>
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    controls={false}
                                    src="/images/lottie-conected.json"
                                    style={{ height: '50px', width: '50px', padding: '0px' }}
                                ></Player>
                                <span style={{ color: '#32d851', fontSize: '0.8em', fontWeight: '400' }}>{t('DeviceInfo.conectado')}</span>
                            </div>

                            <div style={{ position: "absolute", marginTop: "200px" }}>
                                <div className="text-center" style={{ fontSize: "1.6em" }}><Moment format="HH:mm" /></div>
                                <Moment format="dddd, DD MMMM" />
                            </div>


                            {wppAbout && <div className="wppAbout-content" >
                                {wppAbout}
                            </div>}


                            <div className="footer-content1">

                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Files")}>
                                        <img src="/images/files2ico.png" width="45px" height="45px" />
                                        {getNotificationBadge(unreadImages)}
                                        <div className="iconLabel">Imagenes</div>
                                    </div>
                                </Bounce>

                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Location")}>
                                        <img src="/images/locationico.png" width="45px" height="45px" />
                                        {/* {getNotificationBadge(unreadWpp)} */}
                                        <div className="iconLabel">Ubicación</div>
                                    </div>
                                </Bounce>


                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Ajustes")}>
                                        <img src="/images/configico.png" width="45px" height="45px" />
                                        {/* {getNotificationBadge(unreadWpp)} */}
                                        <div className="iconLabel ml-2">Ajustes</div>
                                    </div>
                                </Bounce>

                            </div>


                            <div className="footer-content2">
                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Whatsapp")}>
                                        <img src="/images/wppico.png" width="45px" height="45px" />
                                        {getNotificationBadge(unreadWpp)}
                                        <div className="iconLabel">WhatsApp</div>
                                    </div>
                                </Bounce>

                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Instagram")}>
                                        <img src="/images/intaico.png" width="45px" height="45px" />
                                        {getNotificationBadge(unreadInsta)}
                                        <div className="iconLabel">Instagram</div>
                                    </div>
                                </Bounce>
                                <Bounce delay={getRandomNumber(0, 800)}>
                                    <div onClick={() => handleChannelChange("Facebook")}>
                                        <img src="/images/fbico.png" width="45px" height="45px" />
                                        {getNotificationBadge(unreadFb)}
                                        <div className="iconLabel">Facebook</div>
                                    </div>
                                </Bounce>
                            </div>


                            <i className="fa-sharp fa-regular fa-circle p-2 " onClick={handleHomeClick} style={{ position: "absolute", marginTop: "585px" }} />
                            <i className="fa-regular fa-square  p-2 " onClick={handleHomeClick} style={{ position: "absolute", marginTop: "585px", marginLeft: "200px" }} />
                            <i className="fa fa-chevron-left  p-2 " onClick={handleHomeClick} style={{ position: "absolute", marginTop: "585px", marginLeft: "-200px" }} />
                        </React.Fragment>
                        :
                        <div style={{ paddingTop: '80%' }}>
                            <div className="spinner-border text-primary" role="status"> </div>
                            <div style={{ fontSize: '0.7em', fontWeight: '300' }}>Conectando</div>
                        </div>
                    }


                </div>

                <img className="foreground-image " src="./images/demomobile2.png" />
                {!isLoading ?
                    <Fade duration={2000}>
                        <img className="background-image" src={wppPicture} />
                    </Fade>
                    :
                    <div className="background-image " style={{ backgroundColor: "#000" }} ></div>
                }



            </div>

            <div style={{ position: "", marginTop: "10px" }} className='d-flex justify-content-center '>
                <div className='div mx-3 text-left small'>
                    <div className='text-secondary'>IMEI: {aHardware.imei}</div>
                    <div className='text-secondary'>SERIAL: {aHardware.serial}</div>
                    <div className='text-secondary text-uppercase'>IP: {aNetwork.ip}</div>
                </div>
                <div className='div text-left small'>
                    <div className='text-secondary text-uppercase'>OS: {aHardware.os}</div>
                    <div className='text-secondary'>{t('DeviceInfo.modelo')}: {aHardware.model}</div>
                    <div className='text-secondary text-uppercase'>{t('DeviceInfo.red')}: {aNetwork.domain}</div>
                </div>
            </div>


            {demoMode && <div className='d-flex justify-content-center bg-success'>
                <button className='btn btn-danger btn-block btn-sm font' style={{ position: "fixed", bottom: "10px", width: "97%", zIndex: "9999", height: "37px" }}
                    onClick={() => props.handleBuy(mobile)}>{t('AllData.btnCompleteAccess')} <i className='fa fa-eye ml-1' /></button>
            </div>}

        </div>
    )

}
export default DemoDevice;